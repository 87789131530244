import React, {
    Fragment,
    useState,
    useCallback,
    useContext,
  } from 'react'  
import update from 'immutability-helper'
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    LegacyCard,
    TextField,
    Icon,
    LegacyStack,
    ButtonGroup,
    Button,
    InlineError,
    Badge,
    CalloutCard,
    Banner,
    Tooltip,
    Text,
} from "@shopify/polaris";
import { AppsIcon, CodeIcon } from "@shopify/polaris-icons";
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
const OfferTypeWrapper = styled.div`
    .offer-type-container {
      padding: 25px 150px 40px;
      .nickname-container {
        text-align: center;
        max-width: 430px;
        margin: auto;
        margin-bottom: 35px;
        .nickname-title {
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
        }
      }
      .help-button {
        text-align: center;
        margin-top: 30px;
      }
      .offer-type {
        
        .offer-type-title {
            text-align: center;
            margin-bottom: 25px;
            h3 {
                color: #6D7175;
            }
        }
        .offer-type-option {
            height: 150px;
            width: 150px;
            padding: 20px 20px 5px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            cursor: pointer;
            gap: 20px;
            .offer-type-option-container {
                display: flex;
                flex-direction: row;
                gap: 5px;
                justify-content: center;
                text-align: center;
                .Polaris-Icon {
                    margin-left: -27px;
                    margin-top: 0;
                }
                span {
                    color: #000;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                }
            }
            .offer-enabled {
                svg {
                    fill: #007F5F;
                }
            }
            .offer-type-edit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                button {
                    background: #95C9B4;
                    border-color: #202223;
                }
                .Polaris-Icon {
                    width: 50px;
                    height: 50px;
                }
            }
            .upsell-badge {
                position: absolute;
                bottom: 10px;
                .Polaris-Badge {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                }
            }
            .upsell-beta {
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%)
            }
        }
        .active {
            .Polaris-Card {
                background: #008060;
            }
            .offer-type-option-container {
                span {
                    color:#fff;
                }
            }
            svg {
                fill: #fff;
            }
        }
      }
      .errors {
          padding: 5px 0;
      }
    }
`
function CreateCheckoutType(props) {
    const { location } = props
    const rules = location && location.state && location.state.rules
    const onOfferTypeChange = (offerType) => {
        if ("liquid" === offerType) {
            navigate('/app/offers/create', {
                state: { rules },
                replace: true,
            })
        }else{
            if("extension" === offerType){
                navigate('/app/offers/createCheckout', {
                        state: { rules },
                        replace: true,
                })
            }

        }
    }

    const goToOfferTypes = () => {
        navigate(
            `/app/offers/createOfferType`,
            {
                state: {
                    rules
                },
                replace: true,
            }
        );
    };
    
    return (
        <Page backAction={{
                content: 'Offers',
                onAction: goToOfferTypes}} 
                title="Create Offer">
        <Layout>
            <Layout.Section>
                <LegacyCard>
                <LegacyCard.Section>
                {/*
                <CreateOfferSteps step={1}/>
                */}
                <OfferTypeWrapper>
                    <div className='offer-type-container'>
                        <div className='offer-type'>
                            <div className='offer-type-title'>
                                <Text variant="headingSm" as="h3">What type of checkout do you Have?</Text>
                            </div>
                            <LegacyStack distribution="center">
                            <div className="Polaris-LegacyCard" style={{position: 'relative'}}>
                                <Tooltip content={<div style={{padding:'10px'}}><p style={{fontWeight:'bold',marginBottom:'10px'}}>Checkout.liquid</p><p>Most Shopify checkouts. The standard way to customize your checkout with a theme file.</p></div>} dismissOnMouseOut>
                                <div className="Polaris-LegacyCard">
                                    <div className='offer-type-option' onClick={() => { 
                                            onOfferTypeChange('liquid')
                                        }}>
                                        <div className="offer-type-option-container">
                                           
                                            <div style={{height:'41px'}}>
                                                <span>Checkout.Liquid</span>
                                              
      
                                            </div>
                                        </div>
                                        <div>
                                        <div className='offer-type-edit'>
                                            <Icon
                                                source={CodeIcon}
                                                tone="base" />
                                        </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                </Tooltip>
                            </div>
                           
                            <div className="Polaris-LegacyCard">
                            <Tooltip content={<div style={{padding:'10px'}}><p style={{fontWeight:'bold',marginBottom:'10px'}}>Checkout Extensions</p><p>Shopify’s new checkout, launched Oct 3, 2022. Only for stores that enabled this setting. </p></div>} dismissOnMouseOut>
                                <div className="Polaris-LegacyCard">
                                    <div className='offer-type-option' onClick={() => { 
                                            onOfferTypeChange('extension')
                                        }}>
                                        <div className="offer-type-option-container">
                                           
                                            <div>
                                                <span>Checkout Extensions</span>
                                               
                                            </div>
                                        </div>
                                        <div>
                                        <div className='offer-type-edit'>
                                            <Icon
                                                source={AppsIcon}
                                                tone="base" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </Tooltip>
                            </div>
                            </LegacyStack>
                            <div className='help-button'>
                            <Button

                                onClick={
                                            () => {
                                                if(window && window.$crisp){
                                                    window.$crisp.push(["do", "chat:open"]);
                                                } 
                                            }
                                        }
                                variant="primary">
                                       Help me find out!
                                    </Button>
                            </div>
                            
                        </div>
                    </div>
                    
                    
                </OfferTypeWrapper>
                </LegacyCard.Section>
                </LegacyCard>
            </Layout.Section>
        </Layout>
    </Page>
    );
}

export default CreateCheckoutType;